.menufood {
    padding: 3rem 0 2rem;

    background-color: rgba($color-primary, 1);

    background-color: #e5e5f7;
    background-image:  repeating-linear-gradient(45deg, $color-secondary 25%, transparent 25%, transparent 75%, $color-secondary 75%, $color-secondary), repeating-linear-gradient(45deg, $color-secondary 25%, #e5e5f7 25%, #e5e5f7 75%, $color-secondary 75%, $color-secondary);
    background-position: 0 0, 33px 33px;
    background-size: 66px 66px;

    &__header {
        &__content {
            background-color: $color-white;
            max-width: 450px;
            margin: 0 auto 2rem;
            padding: 1rem 0;
            border-radius: 10px;
        }
        &__title {
            margin-bottom: 0;
        }
    }

    &__list {
        @include reset-list;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    &__item {
        width: 100%;
        margin: 0 auto;
        @include media('md') {
            width: 100%;
            margin: 0;
        }
    }
}

    
