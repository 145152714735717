.team {
    background-color: $color-gallery;
    &__header {
        padding: 2rem 0;
        text-align: center;
        max-width: 750px;
        margin: 0 auto;
        &__content {
            margin-bottom: 1rem;
        }
    }

    &__container {
        margin-bottom: 4rem;
    }

    &__content {

    }

    &__list {
        @include reset-list;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        &__item {
            z-index: 1;
            width: 49%;
            margin-bottom: 1rem;
            @include media('lg') {
                width: 24%;    
            }
        }
    }
}