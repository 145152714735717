//colore 
$color-alzarin: #e74c3c;
$color-sun-flower: #f1c40f;
$color-mine-shaft: #333333;
$color-gallery: #ecf0f1;
$color-white: #FFFFFF;
$color-black: #000000;
$color-mazarine-blue: #273c75;
$color-skirret-green: #44bd32;
$color-nanohanacha-gold: #e1b12c;
$color-mega-azul: #003366;
$color-mega-azul-claro: #0070C0;
$color-mega-gray: #95a5a6;
$color-mega-green: #00D92E;
$color-mex-rojochile: #E63946;
$color-mex-verdejalapeno: #4CAF50;
$color-mex-amarillomaiz: #FFB300;
$color-mex-naranjasalsa: #F27D16;

//redes
$color-facebook: #3b5998;
$color-whatsapp: #25D366;

$color-primary: $color-mex-rojochile;
$color-secondary: $color-mex-verdejalapeno;
$color-tertiary: $color-mex-naranjasalsa;
$color-quaternary: $color-mex-amarillomaiz;
$color-font: $color-mine-shaft;