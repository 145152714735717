.about {
    margin-bottom: 2rem;
    &__header {
        background-color: $color-primary;
        color: $color-white;
        padding: 4rem 0;
        &__container {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            &__column {
                width: 100%;
                @include media('md') {
                    width: 100%;
                }
            }
        }
        &__content {
            margin: 0 auto;
        }
        &__img-container {
            overflow: hidden;
            position: relative;
            border-radius: 5px solid white;
            flex: 0 0 calc(50%);
            padding-top: calc(50%);
        }
        &__img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            &--feature {
                max-width: 80%;
                border-radius: 10px;
            }
        }
    }

    &__content {
        margin: 0 auto 1rem;
        @include media('md') {
            margin-bottom: 1.5rem;
        }
        &__left {
            text-align: left;
        }
        &__right {
            text-align: right;
        }
    }
}