.cardteam {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    &__img {
        height: 280px;
    }

    &__content {
        background-color: $color-white;
        position: absolute;
        padding: 0.5rem;
        bottom: 5%;
        width: 95%;
        left: 2.5%;
        border-radius: 10px;
    }

    &__title {
        display: block;
        font-weight: 500;
    }

    &__description {
        display: block;
        font-style: italic;
    }
}