.cardcontact {
    position: relative;
    width: 100%;

    &__container {
        padding: 0rem 1rem 2rem;
    } 

    &__content {
        text-align: center;
    }

    &__iconcontent {
        display: block;
        width: 100px;
        height: 100px;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
        border-radius: 50%;
        background-color: $color-white;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: -4rem auto 2rem;
    }

    &__title {
        display: block;
        margin-bottom: 1rem;
    }

    &__description {
        display: block;
    }  
}