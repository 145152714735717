.cardpack {
    display: flex;
    flex-wrap: wrap;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    border-radius: 10px;
    overflow: hidden;
    border: 2px solid transparent;
    transition: border ease-in 0.25s, color ease-in 0.25s, background-color ease-in 0.25s;
    &:hover {
        border: 2px solid $color-tertiary;
        .cardpack__text--mesesmegas {
            color: $color-tertiary;
        }
        .btn--primary {
            background-color: $color-tertiary;
        }
    }
    &__content {
        padding: 4rem 0 4rem;
        width: 100%;
        text-align: center;
    }
    &__img {
        &--view {
            max-width: 60%;
            margin-bottom:1rem;
        }
        &--app {
            max-width: 90%;
        }
    }
    &__text {
        &--description {
            display: block;
            margin-bottom: 0;
        }
        &--megas {
            display: block;
            font-size: $fs-bg;
            margin-bottom: 0;
            line-height: 1;
        }
        &--mesesmegas {
            display: block;
            color: $color-primary;
            margin-bottom: 2.5rem;
            font-size: $fs-md;
        }
        &--view {
            display: block;
            font-size: $fs-mn;
            margin-bottom: 1.5rem;
        }
        &--telfija {
            display: block;
            letter-spacing: 0.1rem;
            margin-bottom: 1.5rem;
            font-size: $fs-mn;
        }
        &--apptv {
            display: block;
            text-transform: uppercase;
            font-size: $fs-mn;
            margin-bottom: 1.5rem;
            color: $color-mega-gray;
            font-weight: $fw-5;
        }
        &--precio {
            display: block;
            font-size: $fs-bg;
            line-height: 1;
            font-weight: $fw-6;
        }
        &--preciomes {
            display: inline-block;
            font-size: $fs-sm;
        }
        &--mesesprecio {
            display: block;
            font-size: $fs-md;
            margin-bottom: 2rem;
        }
    }
    &__list-img {
        @include reset-list;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 2rem;
        &__item {
            &:first-child {
                margin-top: 2rem;
            } 
        }
        &__img {
            width: 450px;
            max-width: 100%;
            transition: transform ease-in 0.25s;
            &:hover {
                transform: scale(1.1);
            }
        }
    }
}