.item-menu {
    margin-bottom: 2rem;
    &__container {
        border-radius: 1rem;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        overflow: hidden;
    }
    &__content {
        background-color: $color-white;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        &__column {
            width: 100%;
            &:last-child {
                padding: 1rem 0.5rem;
            }  
            @include media('sm') {
                &:first-child {
                    width: 30%;
                }
                &:last-child {
                    width: 67%;
                }
            }
        }
        &__imagecontainer {
            flex: 0 0 calc(100%);
            padding-top: 66.66%;
            position: relative;
            overflow: hidden;
            @include media('sm') {
                padding-top: 100%;
            }
            @include media('md') {
                padding-top: 66.66%;
            }
        }
        &__image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__title {
        font-size: $fs-lg;
        display: block;
        margin-right: 1rem;
        text-transform: uppercase;
        font-weight: $fw-5;
        letter-spacing: 0.075rem;
        color: $color-primary;
    }
    &__subtitle {
        font-size: $fs-xs;
        display: inline-block;
        font-style: italic;
        padding: 0.25rem 0.75rem;
        background-color: $color-primary;
        color: white;
        font-weight: $fw-5;
        border-radius: 0.5rem;
        margin-bottom: 1rem;
        letter-spacing: 0.025rem;
    }
    &__text {
        display: block;
        font-size: $fs-sm;
        letter-spacing: 0.025rem;
        font-weight: $fw-5;
    }
}