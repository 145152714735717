.banner {
    &--img {
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    //overlays
    &__overlay {
        background-color: rgba(black, .5);
        width: 100%;
        height: 100%;
        position: absolute;
    }

    //hero contenidos
    &__content {
        padding: 9rem 0;
        max-width: 900px;
        margin: 0 auto;
        position: relative;
        color: white;
        text-align: center;
    }
}