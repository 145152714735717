.image {
    &--background {
        width: 100%;
        height: 300px;
        position: relative;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        @include media('sm') {
            height: 350px;
        }
    }
    //overlays
    &__overlay {
        background-color: rgba(black, .5);
        width: 100%;
        height: 100%;
        position: absolute;
    }

    &__logo {
        max-width: 150px;
        margin-bottom: 0.5rem;
    }
}